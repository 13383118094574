.Settings {
  height: 100vh;
  .ScreenContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    .TableContainer {
      flex: 1;
    }
  }
}
