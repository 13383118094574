.Auth {
  width: 100%;
  height: 100vh;
  .Container {
    display: flex;
    height: 100%;
    background-color: var(--base-color-gray);
    .LeftContainer {
      width: 50%;
      height: 100%;
      padding: 88px 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: border-box;
      .TopLogo {
        img {
          width: 120px;
        }
        .MiniDivder {
          width: 72px;
          border-bottom: 2px solid var(--base-color-primary);
          margin-top: 6px;
          margin-left: 2px;
        }
      }

      .MetaInfo {
      }
      .CarouselContainer {
        .CarouselCard {
          padding: 40px 48px;
          background-color: white;
          border-radius: 12px;
          position: relative;
          height: 198px;
          box-sizing: border-box;
          .quoteImage {
            position: absolute;
            top: 0;
          }
          .UserInfo {
            margin-top: 28px;
            display: flex;
            align-items: center;
            img {
              filter: grayscale(100%);
            }
            .UserDetails {
              margin-left: 12px;
            }
          }
        }
      }
    }
    .RightContainer {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 94px 5%;
      box-sizing: border-box;
      .AuthForm {
        padding: 8% 48px;
        box-sizing: border-box;
        width: 100%;
        // height: 100%;
        background-color: white;
        border-radius: 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .FormHeader {
        }
        .FieldContainer {
          width: 100%;
          margin-top: 68px;
          .FieldGroup {
            margin-bottom: 24px;
            width: 100%;
          }
          .aligned {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .WorkspaceCard {
            border-radius: 8px;
            padding: 8px;
            // border: 1px solid var(--base-color-gray);
            display: flex;
            align-items: center;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
              rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
            transition: transform 0.4s;
            cursor: pointer;
            height: 42px;
            margin-right: 16px;
            img {
              width: 38px;
              border-radius: 2px;
              height: 38px;
              margin-right: 6px;
            }
            &:hover {
              border: 2px solid var(--base-color-primary);
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  //ant-design modifiers
  .ant-input-outlined {
    // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.3rem`,
    border-width: 0.13rem !important;
    box-shadow: none;
    &:hover {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.3rem`,
      // border-width: 3px;
      box-shadow: rgba(50, 104, 185, 0.25) 0px 0px 0px 0.25rem !important;
    }
    &:focus {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.3rem`,
      // border-width: 3px !important;
      box-shadow: rgba(50, 104, 185, 0.25) 0px 0px 0px 0.25rem !important;
    }
  }
}
